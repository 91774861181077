import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg">
    <title>Loader Logo</title>

    <svg viewBox="0 0 100 100">
      <g>
        <path
          stroke="#64FFDA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
        />
      </g>

      <svg viewBox="0 0 110 110">
        <g id="B" transform="translate(25.000000, 25.000000)">
          <path
            d="M14,18.4c-0.2,0.2-0.2,0.5-0.1,0.7c0,0.2,0,0.6,0.4,0.4c0.3-0.1,0.5-0.4,0.4-0.7C14.6,18.6,14.4,18.5,14,18.4
				z"
            fill="#64FFDA"
          />
          <path
            d="M31.3,37.9c0.4,0.5,0.9,1,1.3,1.5c1,1.3,2.2,2.5,3.7,3.4c-1.2-1.2-2.4-2.4-3.5-3.5
				C32.4,38.6,32,38.1,31.3,37.9z"
            fill="#64FFDA"
          />
          <path
            d="M30,0.5C13.7,0.5,0.5,13.7,0.5,30S13.7,59.5,30,59.5S59.5,46.3,59.5,30S46.3,0.5,30,0.5z M33.3,43.6
				c-0.3,0.3,0,0.6-0.3,1.1c-3-2.9-6.5-5.3-7.4-9.8c-0.1,0.2-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.2,0.6c-1.4-2.6-2.5-5.1-3.2-7.8
				c-0.1-0.7,0.2-0.6,0.6-0.6c1.2,0,2.5-0.1,3.6-0.6c2.3-1,3.9-2.5,4.1-5.1c0.1-1.2-0.2-2.2-0.9-3.1c-1.4-2-4.9-3-7.9-2.2
				c-2.8,0.7-4.7,2.9-4.9,5.6c-0.2,2-0.2,4.1,0.3,6.2c-2.2-0.6-3.5-1.9-4-4c-0.4-1.5-0.6-3-1.3-4.5c-0.9-2.1,0-4.2,1.3-6
				c0.5,0,0.1,0.6,0.5,0.7c1.2-2.1,3-3.4,5.5-3.6c0.1,0.5-0.5,0.5-0.4,0.9c3.2-0.9,6.5-1,9.8-0.4c4.6,0.8,7.5,3.5,8.3,7.8
				c0.8,4.2-1,8.1-4.8,10.5c-0.5,0.3-1,0.6-1.5,0.9c1.5,1.7,3,3.4,4.3,5.4c-1,0.1-1.8-0.2-2.7-0.4c1,1,2.1,1.6,3.3,2
				c0.6,0.2,1,0.5,1.3,0.9c3.2,4.4,7,7.9,12,10.2c0.1,0,0.1,0.1,0.1,0.2C43.5,49.3,35.6,48.3,33.3,43.6z"
            fill="#64FFDA"
          />
        </g>
      </svg>
    </svg>
  </svg>
);

export default IconLoader;
